import { useAuth } from '../contexts/Auth/useAuth';
import { removeFromStorage } from '../contexts/Auth/utils';
import { Api } from './Api';
import { PROVIDER_IDS } from './providerIds';

const headers = {
  'Content-Type': 'application/json',
};

export const getPopularMoviesAndSeries = async () => {
  const [movies, series, releases] = await Promise.all([
    Api.get('/movies/popular'),
    Api.get('/series/popular'),
    Api.get('/movies/now_playing'),
  ]);

  return {
    movies,
    series,
    releases,
  };
};

export const fetchPopularMovies = async page => {
  try {
    return (await Api.get(`/movies/popular?page=${page}`)).data;
  } catch (error) {
    return error;
  }
};

export const fetchPopularSeries = async page => {
  try {
    return (await Api.get(`/series/popular?page=${page}`)).data;
  } catch (error) {
    return error;
  }
};

export const fetchNowPlaying = async () => {
  try {
    return (await Api.get('/movies/popular')).data;
  } catch (error) {
    return error;
  }
};

export const fetchOnTheAir = async () => {
  try {
    return (await Api.get('/series/popular')).data;
  } catch (error) {
    return error;
  }
};

export const getProviders = async page => {
  return (await Api.get(`/providers?page=${page}&size=50`)).data;
};

export const getRecommendations = async quantity => {
  try {
    return (await Api.get(`/recommendations?size=${quantity}`, { headers }))
      .data;
  } catch (error) {
    return null;
  }
};

export const fetchRecommendationsPaginate = async () => {
  try {
    return (await Api.get('/recommendations?size=20')).data;
  } catch (error) {
    return error;
  }
};

export const getGenres = async () => {
  const genres = await Api.get('/movies/genres', { headers: headers });
  return genres.data;
};

export const getMoviesGenreId = async (id, page) => {
  const genres = await Api.get(`/movies/genre/${id}?page=${page}`, {
    headers: headers,
  });
  return genres.data;
};

export const getSeriesGenreId = async (id, page) => {
  const genres = await Api.get(`/series/genre/${id}?page=${page}`, {
    headers: headers,
  });
  return genres.data;
};

export const getProviderInfo = async (id, page, endpoint) => {
  try {
    return (
      await Api.get(`/${endpoint}/provider/${id}?page=${page}`, {
        headers: headers,
      })
    ).data;
  } catch (error) {
    console.error(error);
  }
};

export const getDiscoverMovie = async page => {
  try {
    return (
      await Api.get(
        `/movies/discover?provider_ids=${PROVIDER_IDS}&page=${page}`,
        { headers: headers },
      )
    ).data;
  } catch (error) {
    console.log(error);
  }
};

export const getDiscoverSerie = async page => {
  try {
    return (
      await Api.get(
        `/series/discover?provider_ids=${PROVIDER_IDS}&page=${page}`,
        { headers: headers },
      )
    ).data;
  } catch (error) {
    console.log(error);
  }
};

export const getMovieInfo = async movieId => {
  try {
    return (await Api.get(`/movies/${movieId}`, headers)).data;
  } catch (error) {
    console.error(error.message);
  }
};

export const getSerieInfo = async serieId => {
  try {
    return (await Api.get(`/series/${serieId}`, headers)).data;
  } catch (error) {
    console.error(error.message);
  }
};

export const getPersonInfo = async personId => {
  try {
    return (await Api.get(`/persons/${personId}`, headers)).data;
  } catch (error) {
    console.error(error.message);
  }
};

export const peopleSearch = async param => {
  try {
    if (param === '') {
      return [];
    }
    return (await Api.get(`/persons/search?query=${param}`)).data;
  } catch (error) {
    console.error(error.message);
  }
};

export const titleSearch = async param => {
  try {
    if (param === '') {
      return [];
    }
    const [movie, serie] = await Promise.all([
      (
        await Api.get(`/movies/search?query=${param}`, {
          headers: headers,
        })
      ).data,
      (
        await Api.get(`/series/search?query=${param}`, {
          headers: headers,
        })
      ).data,
    ]);

    return {
      movie,
      serie,
    };
  } catch (error) {
    console.error(error.message);
  }
};

export const fetchTitle = async (param, page) => {
  try {
    if (param === '') {
      return [];
    }

    const [movie, serie] = await Promise.all([
      (
        await Api.get(`/movies/search?query=${param}&page=${page}`, {
          headers: headers,
        })
      ).data,
      (
        await Api.get(`/series/search?query=${param}&page=${page}`, {
          headers: headers,
        })
      ).data,
    ]);

    return {
      movie,
      serie,
    };
  } catch (error) {
    console.error(error.message);
  }
};

export const fetchMovie = async (param, page) => {
  try {
    if (param === '') {
      return [];
    }

    return (
      await Api.get(`/movies/search?query=${param}&page=${page}`, headers)
    ).data;
  } catch (err) {
    console.log(err.message);
  }
};

export const fetchSerie = async (param, page) => {
  try {
    if (param === '') {
      return [];
    }

    return (
      await Api.get(`/series/search?query=${param}&page=${page}`, headers)
    ).data;
  } catch (err) {
    console.log(err.message);
  }
};

export const fetchPeople = async (param, page) => {
  try {
    if (param === '') {
      return [];
    }
    return (
      await Api.get(`/persons/search?query=${param}&page=${page}`, headers)
    ).data;
  } catch (error) {
    console.error(error.message);
  }
};

export const signIn = async (email, password) => {
  try {
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      accept: 'application/json',
    };

    const form = new FormData();
    form.append('username', email);
    form.append('password', password);

    const request = await Api.post('/auth/login', form, {
      headers: headers,
    });

    return request.data;
  } catch (error) {
    return error;
  }
};

export const signUp = async user => {
  try {
    const request = await Api.post('/users/register', user, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    return request;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const signInWithGoogleAccount = async (email, profile, host) => {
  try {
    return await Api.get(
      `/auth/google/authorize?scopes=${email}&scopes=${profile}&host=${host}`,
      { headers: headers },
    );
  } catch (error) {
    return error;
  }
};

export const signInWithAppleAccount = async data => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  try {
    const response = await Api.post('/social/login', data, headers);
    return response.data;
  } catch (e) {
    console.log(e);
  }
};

export const callbackSignInGoogle = async (code, state, host) => {
  try {
    const response = await Api.get(
      `/auth/google/callback?code=${code}&state=${state}&host=${host}`,
      {
        headers: headers,
      },
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const signInWithFacebookAccount = async (email, profile, host) => {
  try {
    const headers = {
      accept: 'application/json',
    };
    return await Api.get(
      `/auth/facebook/authorize?scopes=${email}&scopes=${profile}&host=${host}`,
      { headers: headers },
    );
  } catch (error) {
    return error;
  }
};

export const callbackSignInFacebook = async (code, state) => {
  try {
    const headers = {
      accept: 'application/json',
    };

    return await Api.get(
      `/auth/facebook/callback?code=${code}&state=${state}`,
      { headers: headers },
    );
  } catch (error) {
    return error;
  }
};

export const Logout = async () => {
  try {
    if (!useAuth.isLoggedIn) {
      return;
    }

    return (await Api.post('/auth/logout', { headers: headers })).data;
  } catch (error) {
    return error;
  }
};

export const fetchUserInfo = async () => {
  try {
    const response = await Api.get('/users/me', { headers: headers });
    return response.data;
  } catch (error) {
    if (error.response.status === 401) {
      removeFromStorage();
    }
    return null;
  }
};

export const forgotPassword = async email => {
  try {
    return await Api.post(
      '/auth/forgot-password',
      { email: email },
      { headers: headers },
    );
  } catch (error) {
    return error;
  }
};

export const getMyFavorites = async () => {
  try {
    const response = await Api.get('/favorites');
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const addToFavorites = async (id, mediaType) => {
  try {
    const form = new FormData();
    form.append('media_id', id);
    form.append('type', mediaType);

    const request = await Api.post('/favorites/add', form, {
      headers: headers,
    });

    return request.data;
  } catch (error) {
    console.log(error);
  }
};

export const removeToFavorites = async (id, mediaType) => {
  try {
    const form = {
      media_id: id,
      type: mediaType,
    };

    const response = await Api.delete('/favorites/remove', {
      headers: headers,
      data: form,
    });

    return response;
  } catch (error) {
    console.log(error);
  }
};

export const getMyWantWatch = async () => {
  try {
    const response = await Api.get('/watchlist', { headers: headers });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const addToWantWatch = async (id, mediaType) => {
  try {
    const form = new FormData();
    form.append('media_id', id);
    form.append('type', mediaType);

    const request = await Api.post('/watchlist/add', form, {
      headers: headers,
    });

    return request.data;
  } catch (error) {
    console.log(error);
  }
};

export const removeToWantWatch = async (id, mediaType) => {
  try {
    const form = {
      media_id: id,
      type: mediaType,
    };

    const response = await Api.delete('/watchlist/remove', {
      headers: headers,
      data: form,
    });

    return response;
  } catch (error) {
    console.log(error);
  }
};

export const getMyAlreadyWatched = async () => {
  try {
    const response = await Api.get('/watched');
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const addToAlreadyWatched = async (id, mediaType) => {
  try {
    const form = new FormData();
    form.append('media_id', id);
    form.append('type', mediaType);

    const request = await Api.post('/watched/add', form, {
      headers: headers,
    });

    return request.data;
  } catch (error) {
    console.log(error);
  }
};

export const removeToAlreadyWatched = async (id, mediaType) => {
  try {
    const form = {
      media_id: id,
      type: mediaType,
    };

    const response = await Api.delete('/watched/remove', {
      headers: headers,
      data: form,
    });

    return response;
  } catch (error) {
    console.log(error);
  }
};

export const ratingPost = async (id, mediaType, rating) => {
  try {
    const form = {
      media_id: id,
      type: mediaType,
      rating: rating,
    };

    const request = await Api.post('/rating', form, {
      headers: headers,
    });

    return request;
  } catch (error) {
    console.log(error);
  }
};

export const getMyGallerys = async () => {
  try {
    const response = await Api.get('/collections');
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getMyCollections = async ids => {
  try {
    const responseArr = [];
    for (const item of ids) {
      const response = await Api.get(`/collections/${item}`);
      responseArr.push(response);
    }
    return responseArr;
  } catch (error) {
    console.log(error);
  }
};

export const addMediaToGallery = async (galleryId, id, mediaType) => {
  try {
    const form = {
      media_id: id,
      type: mediaType,
    };

    const request = await Api.post(`/collections/${galleryId}/add`, form, {
      headers: headers,
    });

    return request.data;
  } catch (error) {
    console.log(error);
  }
};

export const createMyGallery = async (name, description, isPublic) => {
  try {
    const form = {
      name,
      description,
      public: isPublic,
    };

    const request = await Api.post('/collections', form, {
      headers: headers,
    });

    return request.data;
  } catch (error) {
    console.log(error);
  }
};

export const editMyGallery = async (name, description, isPublic, id) => {
  try {
    const form = {
      name,
      description,
      public: isPublic,
    };

    const request = await Api.put(`/collections/${id}`, form, {
      headers: headers,
    });

    return request.data;
  } catch (error) {
    console.log(error);
  }
};

export const deleteMyGallery = async id => {
  try {
    const response = await Api.delete(`collections/${id}`, {
      headers: headers,
    });

    return response;
  } catch (error) {
    console.log(error);
  }
};

export const getMyGallery = async id => {
  try {
    const response = await Api.get(`collections/${id}`, { headers });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getMyGalleryMedias = async id => {
  try {
    const response = await Api.get(`collections/${id}/medias`, { headers });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const deleteMediaFromGallery = async (gallery_id, media_id, type) => {
  try {
    const form = {
      media_id,
      type,
    };

    const response = await Api.delete(`/collections/${gallery_id}/remove`, {
      headers: headers,
      data: form,
    });

    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getStates = async () => {
  try {
    return (
      await Api.get(`/address/country/${22}/states`, { headers: headers })
    ).data;
  } catch (error) {
    return null;
  }
};

export const getCities = async state => {
  try {
    if (!isNaN(state)) {
      return (
        await Api.get(`/address/state/${state}/cities`, { headers: headers })
      ).data;
    }

    return;
  } catch (error) {
    console.log(error);
  }
};

export const updateUserInfo = async user => {
  try {
    const headers = {
      accept: 'application/json',
      'Content-Type': 'application/json',
    };
    return await Api.patch('/users/me', user, {
      headers: headers,
    });
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const updateUserImage = async photo => {
  try {
    const headers = {
      'Content-Type': 'multipart/form-data',
    };

    const form = new FormData();
    photo.type === 'avatar'
      ? form.append('file_photo', photo.file)
      : form.append('file_cover', photo.file);
    return await Api.patch('/users/images/me', form, { headers: headers });
  } catch (error) {
    return null;
  }
};

export const resetPassword = async user => {
  try {
    const headers = {
      accept: 'application/json',
      'Content-type': 'application/json',
    };

    const body = {
      token: user.token,
      password: user.password,
    };
    return await Api.post('/auth/reset-password', body, { headers: headers });
  } catch (error) {
    return error;
  }
};

export const deleteAccount = async () => {
  try {
    const response = await Api.delete('/api/users/me', { headers: headers });
    return response;
  } catch (err) {
    return err;
  }
};

export const getMoreInfo = async (media_type, id) => {
  try {
    return (await Api.get(`/${media_type}s/${id}`, headers)).data;
  } catch (error) {
    console.error(error.message);
  }
};

export const requestVerifyLogin = async email => {
  try {
    return await Api.post('/users/request-verify-token', { email });
  } catch (e) {
    console.log(e.message);
  }
};

export const verifyToken = async token => {
  try {
    return await Api.post('/users/verify', { token });
  } catch (e) {
    return e;
  }
};

export const getRecommendationByFilter = async (filterParams, page) => {
  try {
    Object.keys(filterParams).forEach(
      key => filterParams[key] === undefined && delete filterParams[key],
    );

    const queryString = Object.entries(filterParams)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
      )
      .join('&');

    return (await Api.get(`/recommendations?${queryString}&page=${page}`)).data;
  } catch (e) {
    return e;
  }
};

export const registerQRCode = async code => {
  try {
    const res = await Api.post(`/tv/code/${code}/register`, {
      headers: {
        'Content-Type': 'application/json',
        accept: 'application/json',
        'user-agent': navigator.userAgent,
      },
    });

    return res.data;
  } catch (e) {
    console.log(e);
  }
};

export const getListsArtists = async () => {
  try {
    return (await Api.get('/lists/performer?page=1&size=50')).data;
  } catch (error) {
    return error;
  }
};

export const getListsSagas = async () => {
  try {
    return (await Api.get('/lists/saga?page=1&size=50')).data;
  } catch (error) {
    return error;
  }
};

export const getListSagaId = async id => {
  try {
    return (await Api.get(`/lists/saga/${id}?page=1&size=50`)).data;
  } catch (error) {
    return error;
  }
};
